<div class="cover-area cover-area-property">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 pt-5">
        <img class="img-fluid w-100 pt-5 mt-md-5 mt-0" src="assets/images/digi_market.png" alt="inocentum">
      </div>
      <div class="col-md-5 cover-about" data-aos="fade-in">
        <h1 class="large-heading text-light">Digital Marketing</h1>
        <p class="cover-text">We Are 360°, Digital Marketing Agency, serving many of the most successful and fastest
          growing companies in the world, We offer best digital marketing services including Branding
          Consultancy, Search Engine Optimization, Social Media management, Pay Per Click & Lead
          Generation
          Our Services
          Branding Consultancy
          Give strength to your Brand through Strategy, Positioning and Market Research.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="services">
  <div class="container">
    <h1 class="text-center mb-4">Our Digital Marketing Services</h1>
    <div class="row justify-content-center">
      <div class="col-12 col-md-4">
        <div class="service-item">
          <div class="icon"><i class="fas fa-laptop-code"></i></div>
          <div class="service-text">
            <h4>Branding <span>Consultancy</span></h4>
            <p class="service-para">We help you plan and execute digital marketing campaigns that are personalized, measurable, and most importantly:
              effective. Discover your Brand's Unique Value Proposition and Stand Out from the Competition.</p>
          </div>
<!--          <a class="btn btn-primary" routerLink="/service">Read More</a>-->
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="service-item">
          <div class="icon2"><i class="fas fa-code"></i></div>
          <div class="service-text">
            <h4>Search <span>Engine Optimization</span></h4>
            <p class="service-para">Improve your search engine rankings with a Digital Marketing strategy that works on the whole customer journey. Our SEO
              Expertise will help you get found in Google, Bing and Yahoo.</p>
          </div>
<!--          <a class="btn btn-primary" routerLink="/service">Read More</a>-->
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="service-item">
          <div class="icon3"><i class="fas fa-user-tie"></i></div>
          <div class="service-text">
            <h4>Social<span>Media Marketing </span></h4>
            <p class="service-para">We help our clients to grow their brands with a proven social media strategy and hands-on support. It's time to get the most
              out of your Social Media Marketing with a proven, scalable approach.</p>
          </div>
<!--          <a class="btn btn-primary align-self-end" routerLink="/service">Read More</a>-->
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="service-item">
          <div class="icon4"><i class="fas fa-rocket"></i></div>
          <div class="service-text">
            <h4>PPC/CPC <span>Advertising</span></h4>
            <p class="service-para">We Build, measure and optimize Paid marketing campaigns to achieve results faster.Get more Clients and more Revenue
              with the most Cost-Effective Techniques including best ads Management services.</p>
          </div>
<!--          <a class="btn btn-primary" routerLink="/service">Read More</a>-->
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="service-item">
          <div class="icon5"><i class="fas fa-layer-group"></i></div>
          <div class="service-text">
            <h4>Lead<span>Generation</span></h4>
            <p class="service-para">With our process, you'll get the leads that are already in your market and ready to buy from you.Connect with your customers
              by having them come to you.
            </p>
          </div>
<!--          <a class="btn btn-primary" routerLink="/service">Read More</a>-->
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="service-item">
          <div class="icon6"><i class="fas fa-briefcase"></i></div>
          <div class="service-text">
            <h4>B2B <span>Marketing</span></h4>
            <p class="service-para">We help you plan and execute digital marketing campaigns that are personalized, measurable, and most importantly:
              effective. Discover your Brand's Unique Value Proposition and Stand Out from the Competition.</p>
          </div>
<!--          <a class="btn btn-primary" routerLink="/service">Read More</a>-->
        </div>
      </div>
    </div>
  </div>
</div>
