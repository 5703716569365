import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-t-n-c',
  templateUrl: './t-n-c.component.html',
  styleUrls: ['./t-n-c.component.scss']
})
export class TNCComponent implements OnInit {

  constructor( private title: Title ) { }

  ngOnInit(): void {
    this.title.setTitle('Inocentum | Terms & Condition');
  }

}
