<div class="cover-area cover-area-property">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 pt-5">
        <img class="img-fluid w-100 pt-5" src="assets/images/graphics.png" alt="graphic-design-cover-img">
      </div>
      <div class="col-md-5 cover-about" data-aos="fade-in">
        <h1 class="large-heading text-light">Graphics Design</h1>
        <p class="cover-text">
          Graphic design is a profession for professionals to create visual content to
          communicate information. Designers optimize the user experience by focusing on
          the logic of displaying elements in an interactive layout that uses typography
          and images to meet specific user needs by applying visual hierarchy and page layout techniques.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="container mt-4 mt-md-0">
  <div class="row align-items-center mb-5 mt-md-5 mt-lg-0 mt-0">
    <div class="col-lg-6 mb-5 mb-lg-0">
      <img class="img-fluid" src="/assets/images/about.svg" alt="about-img">
    </div>
    <div class="col-lg-6 col-12">
      <h5 class="heading-color">We Provide Best</h5>
      <h2>Graphics Design</h2>
      <div class="row mt-md-5 mt-4">
       <div class="col-lg-6 col-12 mb-4">
          <h5 class="heading-color">Graphic Design</h5>
          <p class="text-justify">
            Graphic design is a profession for professionals to create visual content to
            communicate information. Designers optimize the user experience by focusing on
            the logic of displaying elements in an interactive layout that uses typography
            and images to meet specific user needs by applying visual hierarchy and page layout techniques.
          </p>
        </div>
        <div class="col-lg-6 col-12 mb-4">
          <h5 class="heading-color">UI/UX Design</h5>
          <p class="text-justify">
            User interface design or user interface engineering is the design of
            user interfaces for systems and software, such as computers, consumer
            electronics, mobile devices, and other electronic devices,
            emphasizing ease of use and maximizing user experience.
          </p>
        </div>
        <div class="col-lg-6 col-12 mb-4">
          <h5 class="heading-color">Video Editing</h5>
          <p class="text-justify">
            Placement and processing of video sequences. Video editing is used to
            create and display all visual information, including rendering positions,
            video advertisements, and images from movies and TV shows.
            Video editing is used to create and display all visual information,
            including movies and TV shows, video ads, etc.
          </p>
        </div>
        <div class="col-lg-6 col-12">
          <h5 class="heading-color">Animation</h5>
          <p class="text-justify">
            Animation is a series of inanimate figures and photographs that captures
            a static image and quickly plays it back in rapid succession to mimic real movements.
            If you've seen flip-books, you know how it works.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
