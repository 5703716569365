import {Component} from '@angular/core';
import {ApiService} from "../api-service/api.service";
declare let $: any;

@Component({
  selector: 'app-blog-cards',
  templateUrl: './blog-cards.component.html',
  styleUrls: ['./blog-cards.component.scss']
})
export class BlogCardsComponent {

  public blogs: any[];

  constructor(private apiService: ApiService) {
    this.getLatestBlogs();
  }

  private getLatestBlogs(){
    this.apiService.getBlogs().subscribe(
      (data: any) => {
        this.blogs = data.results.slice(0, 6);
        setTimeout(() => {
          BlogCardsComponent.initSlick();
        });
      }
    );
  }

  private static initSlick(): void {
    $('.blog-slider').slick({
      dots: false,
      infinite: false,
      arrows: false,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            // dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });
  }
}
