import {AfterViewInit, Component, OnInit} from '@angular/core';
declare const AOS;
@Component({
  selector: 'app-graphics-designing',
  templateUrl: './graphics-designing.component.html',
  styleUrls: ['./graphics-designing.component.scss']
})
export class GraphicsDesigningComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }
}
