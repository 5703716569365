import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ApiService} from '../api-service/api.service';
import { Meta, Title } from '@angular/platform-browser';
declare const AOS;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {
  emailRegex = new RegExp(/[^@]+@[^\.]+\..+/);
  strReg = new RegExp(/^[a-zA-Z\s]*$/);
  // tslint:disable-next-line:max-line-length
  formValues = {email: {value: '', error: false}, name: {value: '', error: false}, phone: {value: '', error: false}, message: {value: '', error: false}};
  alert = {show: false, type: '', message: ''};
  showAlert = showAlert;

  constructor(private apiService: ApiService, private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Inocentum | Contact');
    this.meta.updateTag({ name: 'description', content: 'Start your IT project with Inocentum Technologies' });
    this.meta.updateTag({name: 'robots', content: 'index, follow'});
  }
  ngAfterViewInit(): void {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }

  contactSubmit(event: Event){
    event.preventDefault();
    let condition = true;
    if(this.formValues.email.value.trim().match(this.emailRegex) === null) {
      condition = false;
      this.formValues.email.error = true;
    }
    if(this.formValues.name.value.trim().length < 2 || this.formValues.name.value.trim().match(this.strReg) === null) {
      condition = false;
      this.formValues.name.error = true;
    }
    if(this.formValues.message.value.trim() === '') {
      condition = false;
      this.formValues.message.error = true;
    }
    if(condition){
      document.querySelector('button[type=submit]').setAttribute('disabled', "true");
      const formData = new FormData();
      formData.append('email', this.formValues.email.value);
      formData.append('name', this.formValues.name.value);
      formData.append('phone', this.formValues.phone.value);
      formData.append('message', this.formValues.message.value);
      this.apiService.submitContact(formData).subscribe(
        data =>{
          document.querySelector('button[type=submit]').removeAttribute('disabled');
          this.showAlert(this.alert);
          this.alert.type = 'success';
          this.alert.message = 'Thank you! We will get back you shortly.';
        },
        error => {
          document.querySelector('button[type=submit]').removeAttribute('disabled');
          this.showAlert(this.alert);
          this.alert.type = 'danger';
          this.alert.message = 'Something went wrong, please try again later.';
        }
      );
    }
  }
}

export function showAlert(alert: {
  show: boolean;
}){
  alert.show = true;
  setTimeout(() => {
    alert.show = false;
  }, 10000);
}
