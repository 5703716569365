<div class="container-fluid pt-5 pt-md-0">
  <h1 class="text-center my-5 heading-color">Our Success Story With Ratings</h1>
  <ul class="rating">
    <li class="cards"><img class="img-fluid" src="assets/images/google.png" alt="inocentum"><span><i class="fas fa-star"></i><i class="fas fa-star"></i><i
      class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span></li>
    <li class="cards"><img class="img-fluid" src="assets/images/glassdoor-logo.png" alt="inocentum"><span><i class="fas fa-star"></i><i class="fas fa-star"></i><i
      class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span></li>
    <li class="cards"><img class="img-fluid" src="assets/images/fiverr-logo.png" alt="inocentum"><span><i class="fas fa-star"></i><i class="fas fa-star"></i><i
      class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
    </li>
    <li class="cards"><img class="img-fluid" src="assets/images/JustDial.webp" alt="inocentum"><span><i class="fas fa-star"></i><i class="fas fa-star"></i><i
      class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span>
    </li>
  </ul>
</div>
