import {AfterViewInit, Component} from '@angular/core';
import {ApiService} from "../api-service/api.service";
declare const AOS;
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements AfterViewInit {

  public categories: any[];
  public blogs: any[];
  constructor(private apiService: ApiService) {
    this.getAllBlogCategories();
    this.getLatestBlogs();
  }

  private getAllBlogCategories(){
    this.apiService.getAllCategories().subscribe(
      (data: any[]) => this.categories = data
    );
  }

  private getLatestBlogs(){
    this.apiService.getBlogs().subscribe(
      (data: any) => this.blogs = data.results.slice(0, 2)
    );
  }

  ngAfterViewInit(): void {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }
}
