<div class="bg-cover">
  <div class="container cover-space">
    <div class="content-box">
      <div class="cover-text">
        <h1 class="text-center">Terms &amp; Condition</h1><br>
        <p class="text-justify">
          The terms "We" / "Us" / "Our"/”Company” individually and collectively refer to Inocentum Technologiesand the terms
          "Visitor” ”User” refer to the users. This page states the Terms and Conditions under which you (Visitor) may visit
          this website (“Website”). Please read this page carefully. If you do not accept the Terms and Conditions stated
          here, we would request you to exit this site. The business, any of its business divisions and / or its
          subsidiaries, associate companies or subsidiaries to subsidiaries or such other investment companies (in India or
          abroad) reserve their respective rights to revise these Terms and Conditions at any time by updating this posting.
          You should visit this page periodically to re-appraise yourself of the Terms and Conditions, because they are
          binding on all users of this Website.
        </p><br>
        <p class="text-justify">Terms & conditions defer based on the product & services you choose. We provide an
          appropriate terms & condition document with every order request.
        </p>
        <p class="text-justify mb-0">
          You may contact us via the contact us link to know more in details.
        </p>
      </div>
    </div>
  </div>
</div>
