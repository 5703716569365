<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<div *ngIf="showLoader" class="loader">
  <span>I</span>
  <span>N</span>
  <span>O</span>
  <span>C</span>
  <span>E</span>
  <span>N</span>
  <span>T</span>
  <span>U</span>
  <span>M</span>
  <div class="covers">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
