import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // private apiToken = 'Token 766b4917a87680923e5f44ab8314aea759baa388';
  private apiToken = 'Token a0b078f931278d62b2d020bbde1260c234c291e4';
  private baseUrl = 'https://cp.inocentum.in/api';

  constructor(private http: HttpClient) {
    // if(window.location.href.startsWith('http://localhost')){
    //   this.apiToken = 'Token 023c48dbe8c6a61531731976bd4b229e1ad5f284';
    //   this.baseUrl = 'http://127.0.0.1:8000/api';
    // }
  }

  public submitContact(formData: FormData) {
    const url = this.baseUrl + '/contact_form/';
    return this.http.post(url, formData, {headers: new HttpHeaders({Authorization: this.apiToken})});
  }

  public submitJob(formData: FormData) {
    const url = this.baseUrl + '/job_application/';
    return this.http.post(url, formData, {headers: new HttpHeaders({Authorization: this.apiToken})});
  }

  public getAllCategories(){
    const url = this.baseUrl + '/blog_categories/';
    return this.http.get(url);
  }

  public getBlogs(url?: string, category?: string){
    if(!url) url = this.baseUrl + '/blogs/';
    if(category) url += '?categories__name=' + category;
    return this.http.get(url);
  }

  public getBlogDetails(slug: string){
    const url = this.baseUrl + '/blog_post/' + slug + '/';
    return this.http.get(url);
  }
}
