import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../api-service/api.service";

@Component({
  selector: 'app-single-blog',
  templateUrl: './single-blog.component.html',
  styleUrls: ['./single-blog.component.scss']
})
export class SingleBlogComponent implements OnInit {

  private routeSubscription: Subscription;
  public slug: string;
  public blogDetails;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(
      event => {
        if(event.slug && event.slug.length){
          this.slug = String(event.slug).trim();
          this.getBlogDetails();
        }else this.router.navigate(['no_blog'], {replaceUrl: true}).then();
      }
    );
  }

  private getBlogDetails(){
    this.apiService.getBlogDetails(this.slug).subscribe(
      data => this.blogDetails = data,
      () => this.router.navigate(['no_blog'], {replaceUrl: true}).then()
    );
  }

  public getTags(tagString: string): string[]{
    const tags = tagString.split(',');
    return tags.map(tag => tag.trim());
  }

}
