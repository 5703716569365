import {Component, ElementRef, OnInit} from '@angular/core';
import {Router} from '@angular/router';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'inocentum-technologies',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'inocentum-frontend';
  showLoader = false;
  constructor(private router: Router, private elementRef: ElementRef) {
    elementRef.nativeElement.removeAttribute('ng-version');
    router.events.subscribe(
      event => {
        window.scrollTo({
          top: 0
        });
      }
    );
  }
  ngOnInit(): void {}

  public loader(action: 'show' | 'hide') {
    if (action === 'show') {
      document.body.style.overflow = 'hidden';
      this.showLoader = true;
    } else {
      document.body.style.removeProperty('overflow');
      this.showLoader = false;
    }
  }
}
