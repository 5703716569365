<div class="cover-area cover-area-property">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 pt-5">
        <img class="img-fluid w-100 pt-5 mt-md-5 mt-0" src="assets/images/contact.png" alt="contact-cover-img">
      </div>
      <div class="col-md-5 cover-about" data-aos="fade-in">

        <h1 class="large-heading text-white">Contact Us</h1>
        <p class="cover-text">Want to discuss about your new project or need support for any other topics please feel free to contact us.</p>
      </div>
    </div>
  </div>
</div>

<div class="contact-areas mt-4 mt-md-0">
  <div class="container-fluid">
    <div class="container">
      <h1 class="text-center heading-color mb-3 mt-md-5 mt-lg-0 mt-0">Our Support Team</h1>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6 p-0">
          <div class="contact-item cards">
            <div class="icon bg4"><i class="fas fa-laptop-house"></i></div>
            <div class="contact-text">
              <h4>Office</h4>
              <p>Site Address: Module 44, Webel IT Park Kalyanpur Satellite Township, Asansol - 713305, West Bengal</p>
              <p>Registered Address: Gr. Floor, Ashoka Residency, Vidya Sagar Sarani, Sarada Pally, Asansol - 713304, West Bengal</p>
              <p class="mb-0">Email: <a href="mailto:contact@inocentum.in">contact@inocentum.in</a><br>
                Phone: <a href="tel:+913413556706">+91 3413556706</a><br>
                Mobile: <a href="tel:+919564987234">+91 9564987234</a></p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 p-0">
          <div class="contact-item cards">
            <div class="icon bg1"><i class="fas fa-briefcase"></i></div>
            <div class="contact-text">
              <h4>Sales</h4>
              <p>Looking to place a new order or modify an existing order? Our Sales team can help you find something
                suitable!</p>
              <p class="mb-0">Email: <a href="mailto:sales@inocentum.in">sales@inocentum.in</a><br>
                Mobile: <a href="tel:+919564987234">+91 9564987234</a></p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 p-0 mt-4">
          <div class="contact-item cards">
            <div class="icon bg2"><i class="fas fa-headphones-alt"></i></div>
            <div class="contact-text">
              <h4>Technical Support</h4>
              <p>Receive help for website or server problems, errors, updates, software installations, and any other
                technical questions.</p>
              <p class="mb-0">Email: <a href="mailto:support@inocentum.in">support@inocentum.in</a> <br>
                Mobile: <a href="tel:+917063707547">+91 7063707547</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 p-0 mt-4">
          <div class="contact-item cards">
            <div class="icon bg3"><i class="fas fa-funnel-dollar"></i></div>
            <div class="contact-text">
              <h4>Accounting</h4>
              <p>For questions relating to your invoice, due date extensions and any payment questions or concerns.</p>
                Mobile: <a href="tel:+917001828717">+91 7001828717</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="contact-form" data-aos="fade-up">
  <div class="container">
    <h1 class="text-center mb-5">Get In Touch</h1>
    <form class="row" (submit)="contactSubmit($event)">
      <div *ngIf="alert.show" class="col-12 alert alert-{{alert.type}}" role="alert">
        {{alert.message}}
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control" [class.error-input]="formValues.email.error" placeholder="Email*"
          (change)="formValues.email.value = $event.target['value']; formValues.email.error = false" required>
        <span class="invalid-tooltip" [class.d-block]="formValues.email.error">Please enter valid email id</span>
      </div>
      <div class="form-group col-md-4">
        <input type="text" class="form-control" [class.error-input]="formValues.name.error" placeholder="Name*"
               (change)="formValues.name.value = $event.target['value']; formValues.name.error = false" required>
        <span class="invalid-tooltip" [class.d-block]="formValues.name.error">Please enter valid name</span>
      </div>
      <div class="form-group col-md-4">
        <input type="text" class="form-control" [class.error-input]="formValues.phone.error" placeholder="Phone"
               (change)="formValues.phone.value = $event.target['value']; formValues.phone.error = false">
        <span class="invalid-tooltip" [class.d-block]="formValues.phone.error">Please enter valid phone number</span>
      </div>
      <div class="form-group col-12">
        <textarea class="w-100" [class.error-input]="formValues.message.error" placeholder="Message*"
                    (change)="formValues.message.value = $event.target['value']; formValues.message.error = false"
                  required></textarea>
        <span class="invalid-tooltip" [class.d-block]="formValues.message.error">Please enter valid message</span>
      </div>
      <div class="col-12 text-center">
        <p>Note : '*' marked fields are mandatory</p>
      </div>
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</div>

<iframe
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14614.172030006686!2d86.9444404!3d23.6922954!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3b2d0c4b9694e53!2sInocentum%20Technologies!5e0!3m2!1sen!2sin!4v1590039816992!5m2!1sen!2sin"
  width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
  tabindex="0"></iframe>


