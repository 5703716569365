<footer>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4">
        <h3 class="text-white mb-2 mb-lg-4 mb-md-4">Site Menu</h3>
        <ul class="menu">
          <li><a routerLink=""><i class="fas fa-angle-right"></i> Home</a></li>
          <li><a routerLink="service"><i class="fas fa-angle-right"></i> Services</a></li>
<!--          <li><a routerLink="/portfolio"><i class="fas fa-angle-right"></i> Portfolio</a></li>-->
          <li><a routerLink="/career"><i class="fas fa-angle-right"></i> Career</a></li>
          <li><a routerLink="/digital_marketing"><i class="fas fa-angle-right"></i> Digital Marketing</a></li>
          <li><a routerLink="/graphics_designing"><i class="fas fa-angle-right"></i> Graphics Design</a></li>
          <li><a routerLink="/contact"><i class="fas fa-angle-right"></i> Contact</a></li>
          <li><a routerLink="/blog"><i class="fas fa-angle-right"></i> Blog</a></li>
        </ul>
      </div>
      <div class="col-12 col-md-4">
        <h3 class="text-white mb-2 mb-lg-4 mb-md-4">Useful Links</h3>
        <ul class="secondary-menu">
          <li><a routerLink="/privacy_policy"><i class="fas fa-angle-right"></i> Privacy Policy</a></li>
          <li><a routerLink="/return_policy" href=""><i class="fas fa-angle-right"></i> Return, Refund &amp; Cancellation Policy</a></li>
          <li><a routerLink="/terms_and_conditions"><i class="fas fa-angle-right"></i> Terms & Conditions</a></li>
        </ul>
        <h3 class="text-white my-2 my-lg-4 my-md-4">Social Links</h3>
        <ul class="social-menu">
          <li>
            <a href="https://www.facebook.com/inocentum/" class="social-button" target="_blank">
              <span class="icon"><i class="fab fa-facebook-f"></i></span>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/inocentum" target="_blank" class="social-button"><span class="icon"><i class="fab fa-linkedin-in"></i></span></a>
          </li>
          <li>
            <a href="https://twitter.com/InocentumTech" target="_blank" class="social-button"><span class="icon"><i class="fab fa-twitter"></i></span></a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-4">
        <h3 class="text-white mb-2 mb-lg-4 mb-md-4">Payment Options</h3>
        <ul class="secondary-menu text-light">
          <li><i class="fas fa-angle-right"></i> UPI (G Pay, PhonePe, etc.)</li>
          <li><i class="fas fa-angle-right"></i> NEFT/RTGS/IMPS</li>
          <li><i class="fas fa-angle-right"></i> Wire Transfer (USD Only)</li>
          <li><i class="fas fa-angle-right"></i> PayPal<sup>&trade;</sup></li>
        </ul>
<!--        <img class="img-fluid" src="assets/images/card.png" alt="inocentum">-->
      </div>
    </div>
  </div>
</footer>
<div class="footline">
  <div class="container-fluid text-center">
    <a class="company" href="">© 2019-<span id="footerCurrentYear"></span>, <span class="hover-span"> Inocentum Technologies</span> - All Rights Reserved</a>
    <br>
<!--    <a href='https://www.freepik.com/vectors/social-media'>Social media vector created by vectorjuice - www.freepik.com</a>-->
  </div>
</div>
