import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ContactComponent} from './contact/contact.component';
import {HomeComponent} from './home/home.component';
import {ServiceComponent} from './service/service.component';
import {CareerComponent} from './career/career.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {ReturnPolicyComponent} from './return-policy/return-policy.component';
import {TNCComponent} from './t-n-c/t-n-c.component';
// import {PortfolioComponent} from './portfolio/portfolio.component';
import {DigitalMarketingComponent} from './digital-marketing/digital-marketing.component';
import {GraphicsDesigningComponent} from './graphics-designing/graphics-designing.component';
import {BlogComponent} from './blog/blog.component';
import {BlogCardsComponent} from './blog-cards/blog-cards.component';
import {BlogDetailsComponent} from './blog-details/blog-details.component';
import {SingleBlogComponent} from './single-blog/single-blog.component';
import {NoBlogComponent} from './no-blog/no-blog.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'service', component: ServiceComponent},
  {path: 'career', component: CareerComponent},
  {path: 'privacy_policy', component: PrivacyComponent},
  {path: 'return_policy', component: ReturnPolicyComponent},
  {path: 'terms_and_conditions', component: TNCComponent},
  // {path: 'portfolio', component: PortfolioComponent},
  {path: 'digital_marketing', component: DigitalMarketingComponent},
  {path: 'graphics_designing', component: GraphicsDesigningComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'blog_cards', component: BlogCardsComponent},
  {path: 'blog_details/:category', component: BlogDetailsComponent},
  {path: 'single_blog/:slug', component: SingleBlogComponent},
  {path: 'no_blog', component: NoBlogComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
