<div class="cover-area cover-area-property">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-center pt-5">
        <img class="img-fluid w-100 pt-5 mt-md-5 mt-0" src="assets/images/home.png" alt="home-cover-img">
      </div>
      <div class="col-md-5 cover-about" data-aos="fade-in">
        <h1 class="large-heading text-light">We Innovate</h1>
        <div class="cover-text">
          <p>Inocentum Technologies is a professionally managed software development and consulting house. We love to
            help Entrepreneurs. Here at Inocentum Technologies, we aim to create innovative IT solutions that will
            revolutionize the way businesses operate. We don’t just want to make client projects stand out; we want to
            help them thrive.
            We deliver innovative IT solutions that fit the needs of our clients and save them time, money, and
            headaches.
          </p>
          <p>
            Our expertise ranges from Website Development, Social Media Marketing, SEO, Mobile App Development to
            Software and Application Development.
            Our highly experienced IT team is all geared up to implement the needful and even tailor-made solutions!
            We believe we have something to learn from everyone and so we’re always ready to innovate, collaborate, and
            bring a fresh eye to the problem.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!----------------------------------about----------------------------------->
<div class="about">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 text-center my-5">
        <h1 class="large-heading heading-color">About Us</h1>
        <h2 class="sub-text">“Committed Professionals with the promise of steadfast services”
        </h2>
      </div>
      <div class="col-12 col-md-8 col-lg-6">
        <img class="img-fluid" src="assets/images/about.svg" alt="about-img">
        <h2 class="text-center my-3">Not just a company, but also your partner</h2>
        <p class="about-text text-center">Are you trying to reach your right audiences but feeling to engage them with
          your web? Then join us, Innocentum technologies to give your web the right boost and take your business to new
          heights. Our team of developer designers and business professionals have profound knowledge to provide
          top-notch services guarantying you with full security and privacy. <br>
          Talent, dedication, and expertise are the defining characteristics of our team. We believe in direct
          communication and collaboration with our clients; thereby remaining focused on our work using the latest
          technologies and business tactics to enhance your profitability. While our company has grown since these
          years, our staunch dedication in treating each customer has remained unchanged.
        </p>
      </div>
    </div>
  </div>
</div>
<!----------------------------------services--------------------------------->
<div class="services">
  <div class="container">
    <h1 class="text-center mb-4">Our Services</h1>
    <div class="row justify-content-center">
      <div class="col-12 col-md-4" data-aos="fade-in">
        <div class="service-item">
          <div class="icon"><i class="fas fa-laptop-code"></i></div>
          <div class="service-text">
            <h4>Web <span>Development</span></h4>
            <p class="service-para">We offer website design services. We can build blogs, portfolios, landing pages,
              image galleries, etc.
              for you. We offer both, static and as well as dynamic websites. We also make single page websites.</p>
          </div>
          <a class="btn btn-primary" routerLink="/service">Read More</a>
        </div>
      </div>
      <div class="col-12 col-md-4" data-aos="fade-out">
        <div class="service-item">
          <div class="icon2"><i class="fas fa-code"></i></div>
          <div class="service-text">
            <h4>App <span>Development</span></h4>
            <p class="service-para">Want to bring your website to more users via a mobile application for Android, we
              will do it for you. We
              build stand alone android apps, customer support android app, Online Travel Agency, E-commerce store,
              etc.</p>
          </div>
          <a class="btn btn-primary" routerLink="/service">Read More</a>
        </div>
      </div>
      <div class="col-12 col-md-4" data-aos="fade-in">
        <div class="service-item">
          <div class="icon3"><i class="fas fa-user-tie"></i></div>
          <div class="service-text">
            <h4>Business <span>Consultancy</span></h4>
            <p class="service-para">Want to start a business or change current business model, we are provide market
              leading ideas and
              business model the help you reach your business goal.</p>
          </div>
          <a class="btn btn-primary align-self-end" routerLink="/service">Read More</a>
        </div>
      </div>
      <div class="col-12 col-md-4" data-aos="fade-out">
        <div class="service-item">
          <div class="icon4"><i class="fas fa-rocket"></i></div>
          <div class="service-text">
            <h4>Startup <span>Booster</span></h4>
            <p class="service-para">Boost your startup with us. We will help you with tools, knowledge, business models
              etc that help you to
              bring your big idea to the market.</p>
          </div>
          <a class="btn btn-primary" routerLink="/service">Read More</a>
        </div>
      </div>
      <div class="col-12 col-md-4" data-aos="fade-in">
        <div class="service-item">
          <div class="icon5"><i class="fas fa-layer-group"></i></div>
          <div class="service-text">
            <h4>UI & UX <span>Design</span></h4>
            <p class="service-para">With our years of experience and research we know that which UI you customer love to
              use. A smooth UI
              make the application perfect. Our UX & UI team work together to craft the best in class and modern
              application user interface for you.
            </p>
          </div>
          <a class="btn btn-primary" routerLink="/service">Read More</a>
        </div>
      </div>
      <div class="col-12 col-md-4" data-aos="fade-out">
        <div class="service-item">
          <div class="icon6"><i class="fas fa-cogs"></i></div>
          <div class="service-text">
            <h4>Desktop <span>Software</span></h4>
            <p class="service-para">Looking for a billing software or stock management software which works offline
              without the need of
              internet? You have come to the right place. We offer native software development services of billing,
              invoicing, store management, etc. for Microsoft Windows, LINUX and MAC.</p>
          </div>
          <a class="btn btn-primary" routerLink="/service">Read More</a>
        </div>
      </div>
      <div class="col-12 col-md-4" data-aos="fade-in">
        <div class="service-item">
          <div class="icon7"><i class="fas fa-project-diagram"></i></div>
          <div class="service-text">
            <h4>Project <span>Architecture</span></h4>
            <p class="service-para">Have a large project that need a strong architecture? Our experience team of
              professional help you with
              to create a perfect architecture for yor project.</p>
          </div>
          <a class="btn btn-primary" routerLink="/service">Read More</a>
        </div>
      </div>
      <div class="col-12 col-md-4" data-aos="fade-out">
        <div class="service-item">
          <div class="icon8"><i class="fas fa-headset"></i></div>
          <div class="service-text">
            <h4>Technical <span>Support</span></h4>
            <p class="service-para"> We take after sells support very seriously to maintain good market reputation and
              healthy business
              reletionship.
              Need technical support to fix or maintain your existing application. We provide 24X7 technical support to
              you.</p>
          </div>
          <a class="btn btn-primary" routerLink="/service">Read More</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!--------------------------------technologies------------------------------>
<h1 class="mb-lg-5 mb-md-5 mb-4 text-center heading-color">Technologies We Use</h1>
<div class="mobile-section">
  <div class="container">
    <ul class="technology" data-aos="fade-in">
      <li class="box1"><a><img src="assets/images/icons8-html-filetype-24.png"
                               alt="inocentum"><span>HTML/SCSS</span></a></li>
      <li class="box2"><a><i class="fab fa-js-square"></i><span>JavaScript</span></a></li>
      <li class="box3"><a><i class="fab fa-python"></i><span>Python</span></a></li>
      <li class="box4"><a><img src="assets/images/icons8-django-24.png" alt="django"/><span>Django</span></a></li>
      <li class="box5"><a><i class="fab fa-php"></i><span>Php</span></a></li>
      <li class="box6"><a><i class="fas fa-fire"></i><span>Codeignitor</span></a></li>
      <li class="box7"><a><i class="fab fa-node-js"></i><span>NodeJs</span></a></li>
      <li class="box8"><a><i class="fas fa-atom"></i><span>Electron</span></a></li>
      <li class="box7"><a><i class="fab fa-angular"></i><span>Angular</span></a></li>
      <li class="box1"><a><i class="fab fa-bootstrap"></i><span>Bootstrap</span></a></li>
      <li class="box8"><a><i class="fab fa-java"></i><span>Java</span></a></li>
      <li class="box5"><a><img src="assets/images/icons8-kotlin-24.png" alt="kotlin"/><span>Kotlin</span></a></li>
      <li class="box4"><a><img src="assets/images/icons8-flutter-24.png" alt="flutter"/><span>Flutter</span></a></li>
      <li class="box3"><a><i class="fas fa-laptop-code"></i><span>Cordova</span></a></li>
      <li class="box2"><a><i class="fab fa-android"></i><span>Native Android</span></a></li>
      <li class="box1"><a><i class="fab fa-react"></i><span>React</span></a></li>
      <li class="box8"><a><i class="fas fa-code"></i><span>Dotnet</span></a></li>
    </ul>
  </div>
</div>
<!--who are we-->
<div class="container">
  <div class="row">
    <div class="col-12" data-aos="fade-out">
      <h1 class="mb-lg-5 mb-md-5 mb-4 text-center heading-color">Who are we?</h1>
      Being one of the top-rated Web Development Company, we have experts specializing in every department, who are
      trying to deliver an integrated marketing approach, and is trying to keep you ensured about your service so that
      you can achieve the best results. We value your communication and always try to stay connected with our customers.
      From exclusive planning in the initial stages of the campaign to the preparation of monthly reports, you can get
      benefitted from us in every possible way. We will keep on innovating new ideas while pushing the boundaries of
      conventional research.
    </div>
    <div class="col-12 mt-5" data-aos="fade-up">
      <h1 class="mb-lg-5 mb-md-5 mb-4 text-center heading-color">Why are we best than the rest?</h1>
      What makes our web development process unique? Our experts help you to validate your ideas by conducting market
      research and user studies to help you to evolve the right product. Our web developers with cross-platform
      expertise and command over business logic will ensure you that your service is ready for testing within a few
      days. As a customer-centric web development company, we will ensure that your product is built for the right
      cause, has the right features, and can be launched in the market at the right time when it will gain maximum
      momentum.
    </div>
  </div>
</div>
<!--mission and vision-->
<app-mission></app-mission>

<!-----------------------google partners---------------------------------->
<div class="google-partner" data-aos="fade-up">
  <div class="container-fluid">
    <h1 class="text-center heading-color mb-4">Google products we offer as a google cloud partner</h1>
    <ul>
      <li><img class="img-fluid" src="assets/images/cloud_lockup_vert.png" alt="inocentum"></li>
      <li><img class="img-fluid" src="assets/images/gcp_lockup_vert.png" alt="inocentum"></li>
      <li><img class="img-fluid" src="assets/images/google_map.png" alt="inocentum"></li>
      <li><img class="img-fluid" src="assets/images/chrome_lockup_vert.png" alt="inocentum"></li>
      <li><img class="img-fluid" src="assets/images/g-suite.png" alt="inocentum"></li>
    </ul>
  </div>
</div>

<!-----------------------------------pathfinders-------------------------------->
<div class="pathfinder" data-aos="fade-down">
  <div class="container-fluid">
    <h1 class="text-center heading-color mb-4">Our Technological Pathfinders</h1>
    <ul>
      <!--<li><img class="img-fluid" src="assets/images/Sabre_Corporation_logo.svg" alt="inocentum"></li>-->
      <li><img class="img-fluid" src="assets/images/jetbrains.png" alt="inocentum"></li>
      <li><img class="img-fluid" src="assets/images/github.png" alt="inocentum"></li>
      <li><img class="img-fluid" src="assets/images/cpanel-logo.png" alt="inocentum"></li>
      <li><img class="img-fluid" src="assets/images/Wave_logo.svg" alt="inocentum"></li>
    </ul>
  </div>
</div>


<!--clients-->
<app-client></app-client>
<!--testimonial-->
<app-testimonial></app-testimonial>
<!--blogs-->
<h1 class="text-center heading-color mt-5 mb-4 pt-md-0 pt-5">Latest Blog Posts</h1>
<app-blog-cards></app-blog-cards>
<!--ratings-->
<app-rating></app-rating>
