import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  currentPage = '/';

  constructor(private router: Router) {
    router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        this.currentPage = data.url;
      }
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    window.onscroll = () => {
      const scroll = window.scrollY;
      const header = document.getElementsByTagName('header')[0];
      if (scroll > 50) {
        header.classList.add('solid');
      } else { header.classList.remove('solid'); }
    };
    document.onclick = () => {
      if (document.getElementById('navbarNav').classList.contains('show')) {
        document.getElementById('toggle').click();
      }
    };
  }
}

