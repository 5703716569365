<div class="blog-slider px-md-5 px-0">
  <div *ngFor="let blog of blogs" class="blog-cards">
    <div class="blog-img">
      <img class="img-fluid" [src]="blog.feature_image"
           [alt]="blog.slug">
    </div>
    <div class="blog-texts position-relative">
      <h4 class="heading-color">{{blog.title}}</h4>
      <p class="d-none" [innerHTML]="blog.body"></p>
      <div class="d-flex w-100 align-items-center">
        <small class="text-secondary">{{blog.date_time | date: 'medium'}}</small>
        <a class="btn btn-primary blog-btn ml-auto" [routerLink]="['single_blog', blog.slug]">Read More</a>
      </div>
    </div>
  </div>
</div>
