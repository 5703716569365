import { Component, OnInit } from '@angular/core';
// @ts-ignore
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('#footerCurrentYear').text(new Date().getFullYear());
  }

}
