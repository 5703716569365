<header>
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" href="#"><img class="img-fluid" src="assets/images/logo6.png" alt="inocentum-white"></a>
      <a class="slide-logo" href="#"><img class="img-fluid" src="assets/images/logo_new.svg" alt="inocentum-solid"></a>
      <button type="button" id="toggle" class="navbar-toggler" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon">
         <span class="top-line"></span>
        <span class="middle-line"></span>
        <span class="bottom-line"></span>
      </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item {{currentPage === '/' ? 'active' : ''}}">
            <a class="nav-link" routerLink=""><i class="fas fa-home"></i> Home <span
              class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item {{currentPage === '/service' ? 'active' : ''}}">
            <a class="nav-link" routerLink="service"><i class="fas fa-users-cog"></i> Services</a>
          </li>
<!--          <li class="nav-item {{currentPage === '/portfolio' ? 'active' : ''}}">-->
<!--            <a class="nav-link" routerLink="/portfolio"><i class="far fa-edit"></i> Portfolio</a>-->
<!--          </li>-->
          <li class="nav-item {{currentPage === '/digital_marketing' ? 'active' : ''}}">
            <a class="nav-link" routerLink="/digital_marketing"><i class="fas fa-chart-line"></i> Digital Marketing</a>
          </li>
          <li class="nav-item {{currentPage === '/graphics_designing' ? 'active' : ''}}">
            <a class="nav-link" routerLink="/graphics_designing"><i class="fas fa-palette"></i> Graphics Designing</a>
          </li>
          <li class="nav-item {{currentPage === '/career' ? 'active' : ''}}">
            <a class="nav-link" routerLink="/career"><i class="fas fa-briefcase"></i> Career</a>
          </li>
          <li class="nav-item {{currentPage === '/contact' ? 'active' : ''}}">
            <a class="nav-link" routerLink="/contact"><i class="far fa-building"></i> Contact</a>
          </li>
<!--          <li class="nav-item {{currentPage === '/our-team' ? 'active' : ''}}">-->
<!--            <a class="nav-link" routerLink="/our-team"><i class="far fa-building"></i> Our Team</a>-->
<!--          </li>-->
          <li class="nav-item {{currentPage === '/blog' ? 'active' : ''}}">
            <a class="nav-link" routerLink="/blog"><i class="fas fa-blog"></i> Blog</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
