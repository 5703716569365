<div class="cover-area cover-area-property">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 pt-5">
        <img class="img-fluid w-100 pt-5 mt-md-5 mt-0" src="assets/images/career.png" alt="career-cover-img">
      </div>
      <div class="col-md-5 cover-about" data-aos="fade-in">
        <h1 class="large-heading text-white mb-md-auto mb-0">Choose Your Career</h1>
        <p class="cover-text"> Apply now to start your tech journey with us!</p>
      </div>
    </div>
  </div>
</div>

<div class="about-career">
  <div class="container">
    <h1 class="text-center heading-color mb-md-5 mb-1">Career Opportunities</h1>
    <div class="row align-items-center">
      <div class="col-md-6 text-lg-left text-md-left text-center">
        <h4 class="mb-3"> Choose Your Job</h4>
        <p>We are hiring potential candidates in various fields such as development, designing, digital marketing, etc.
          If your looking for a job apply by clicking on the "Apply Now" button.</p>
        <a class="btn btn-primary" data-toggle="modal" data-target="#apply_now_modal">
          Apply Now
        </a>
      </div>
      <div class="col-md-6">
        <div class="job-image p-0">
          <img class="img-fluid" src="assets/images/job_hunt.svg" alt="inocentum">
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="apply_now_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content text-center">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Apply Here</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body cards">
        <form (submit)="careerSubmit($event)">
          <div class="form-row">
            <div *ngIf="alert.show" class="col-12 alert alert-{{alert.type}}" role="alert">
              {{alert.message}}
            </div>
            <div class="col-md-6 form-group">
              <input type="text" class="form-control" [class.error-input]="formValues.firstName.error"
                     placeholder="First name"
                     (change)="formValues.firstName.value = $event.target['value']; formValues.firstName.error = false;"
                     required>
              <span class="invalid-tooltip" [class.d-block]="formValues.firstName.error">Please enter valid name</span>
            </div>
            <div class="col-md-6 form-group">
              <input type="text" class="form-control" placeholder="Last name"
                     (change)="formValues.lastName.value = $event.target['value']">
            </div>
            <div class="col-md-6 form-group">
              <input type="email" class="form-control" [class.error-input]="formValues.email.error" placeholder="Email"
                     (change)="formValues.email.value = $event.target['value']; formValues.email.error = false"
                     required>
              <span class="invalid-tooltip" [class.d-block]="formValues.email.error">Please enter valid email id</span>
            </div>
            <div class="col-md-6 form-group">
              <select class="form-control" [class.error-input]="formValues.desiredField.error"
                      (change)="formValues.desiredField.value = $event.target['value']; formValues.desiredField.error = false"
                      required>
                <option value="">Desired Field</option>
                <option value="Development (Front-end)">Development (Front-end)</option>
                <option value="Development (Back-end)">Development (Back-end)</option>
                <option value="Development (Full Stack)">Development (Full Stack)</option>
                <option value="Graphics Designing">Graphics Designing</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Content Writing">Content Writing</option>
                <option value="Management">Management</option>
              </select>
              <span class="invalid-tooltip" [class.d-block]="formValues.desiredField.error">Please select your desired field</span>
            </div>
            <div class="col-12 form-group">
              <label for="cv">Upload Resume</label>
              <input type="file" id="cv" class="form-control h-auto p-0" [class.error-input]="formValues.file.error"
                     placeholder="Upload CV"
                     (change)="formValues.file.value = $event.target['files'][0]; formValues.file.error = false"
                     accept=".pdf" required>
              <span class="invalid-tooltip" [class.d-block]="formValues.file.error">Please select only PDF files</span>
            </div>
          </div>
          <div class="col-12">
            <p>All are mandatory fields</p>
          </div>
          <div class="col-12 form-group">
            <button type="submit" class="btn btn-primary" #modalSubmitBtn>
              Submit
              <i *ngIf="modalSubmitBtn.disabled" class="fa fa-spinner fa-spin ml-2"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
