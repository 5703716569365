import { Component, OnInit, AfterViewInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
declare const AOS;
export interface Service{
  title: string,
  description: string
}

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit, AfterViewInit {

  public services: Service[] = [
    {
      title: 'Web Development',
      description: `We offer website design services. We can build blogs, portfolios, landing pages, image galleries, etc. for you.
        We offer both, static and as well as dynamic websites. We also make single page websites.`
    },
    {
      title: 'Business Consultancy',
      description: `Want to start a business or change current business model, we are provide market leading ideas and business
        model the help you reach your business goal.`
    },
    {
      title: 'App Development',
      description: `Want to bring your website to more users via a mobile application for Android, we will do it for you. We build
        stand alone android apps, customer support android app, Online Travel Agency, E-commerce store, etc.`
    },
    {
      title: 'Startup Booster',
      description: `Boost your startup with us. We will help you with tools, knowledge, business models etc that help you to bring
        your big idea to the market.`
    },
    {
      title: 'Desktop Software',
      description: `Looking for a billing software or stock management software which works offline without the need of internet?
        You have come to the right place. We offer native software development services of billing, invoicing, store
        management, etc. for Microsoft Windows, LINUX and MAC.`
    },
    {
      title: 'UI & UX Design',
      description: `With our years of experience and research we know that which UI you customer love to use. A smooth UI make the
        application perfect. Our UX & UI team work together to craft the best in class and modern application user
        interface for you.`
    },
    {
      title: 'Project Architecture',
      description: `Have a large project that need a strong architecture? Our experience team of professional help you with to
        create a perfect architecture for yor project.`
    },
    {
      title: 'Technical Support',
      description: `We take after sells support very seriously to maintain good market reputation and healthy business reletionship.
        Need technical support to fix or maintain your existing application. We provide 24X7 technical support to you.`
    }
  ];
  public selectedServiceIDToShow = 0;

  constructor(private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Inocentum | Services');
    this.meta.updateTag({ name: 'description', content: 'World class services provided my Inocentum' });
    this.meta.updateTag({name: 'robots', content: 'index, follow'});
  }
  ngAfterViewInit(): void {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }

}
