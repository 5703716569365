<div class="mission-vision">
  <div class="container">
    <div class="row mission align-items-center" data-aos="fade-up">
      <div class="col-12 col-md-6 mission-text text-lg-left text-md-left text-center">
        <h1 class="heading-color">Our Mission</h1>
        <p class="text-justify"> We thrive to help our customers expand their business with the most striking and
          engaging creativity in the form of website and application design and development. Our role also extends to
          providing you with assistance to create a dominating position in the market.
        </p>
      </div>
      <div class="col-12 col-md-6 mission-image">
        <img class="img-fluid" src="assets/images/mission.svg" alt="inocentum">
      </div>
    </div>
    <hr>
    <div class="row vision align-items-center" data-aos="fade-down">
      <div class="col-12 col-md-6 vision-image">
        <img class="img-fluid" src="assets/images/vision.svg" alt="inocentum">
      </div>
      <div class="col-12 col-md-6 vision-text text-center text-lg-right text-md-right">
        <h1 class="heading-color">Our Vision</h1>
        <p class="text-justify">Being one of the trusted companies, Inocentum technologies provides you the best
          solution to your problems with the right websites and applications, and other software. Through our solutions,
          you will be able to face the ever-changing market and compete with other organizations.

        </p>

        <p class="text-justify"> We understand that the
          requirements of the idea vary from company to company. Therefore, we have created a flexible methodology that
          is customized according to the needs of the clients. </p>

        <p class="text-justify"> Today’s market requires those who can provide ‘Round Clock Services’; therefore, we have hired professionals
          who are ready to showcase their talent through their accurate services.</p>
      </div>
    </div>
  </div>
</div>
