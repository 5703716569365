<div class="cover-section">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div *ngIf="blogDetails; else loading" class="cards bg-white shadow">
          <div class="single-blog-img w-100 mb-3">
            <img class="img-fluid" [src]="blogDetails.feature_image">
          </div>
          <small class="mt-3 mb-0">Date: {{blogDetails.date_time | date: 'medium'}}</small>
          <h2 class="heading-color my-1">{{blogDetails.title}}</h2>
          <section class="mb-3">
            <span class="text-muted">Tags: </span>
            <span *ngFor="let tag of getTags(blogDetails.tags)" class="badge badge-info mr-1">{{tag}}</span>
            |
            <span class="text-muted">Categories: </span>
            <a *ngFor="let category of blogDetails.categories" [routerLink]="['/blog_details', category.name]" class="badge badge-secondary mr-1">{{category.name}}</a>
          </section>
          <p class="text-justify" [innerHTML]="blogDetails.body"></p>
        </div>
        <ng-template #loading>
          <div class="cards bg-white skeleton-loader" id="skeleton_body"></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>


