<div class="testimonial-wrap">
  <div class="container-fluid">
    <h1 class="text-center text-white pt-lg-5 pt-md-5 pt-4 pb-lg-3 pb-md-3 pb-1">Testimonials</h1>
    <div class="testimonial">
      <div class="item cards">
        <p class="item-text mb-0"><i class="fas fa-quote-left"></i> Over all you & your team are great well understand
          the customer only suggestion is before starting the job give the targeted date so every one feel the pinch of
          tightness thanks for the giving a great family environment during project my wish to the God I like to see you
          guys become the top developer of the world. Thanks & Best <i class="fas fa-quote-right"></i></p>
        <div class="image">
          <img class="img-fluid" src="assets/images/test-img.svg" alt="inocentum">
          <div class="name">Md. Rafi</div>
          <h5 class="company">Travel Abroad Inc.</h5>
        </div>
      </div>
      <div class="item cards">
        <p class="item-text mb-0"><i class="fas fa-quote-left"></i>I had a very good experience..u totally helped me out
          with everything I wanted..thank you<i class="fas fa-quote-right"></i></p>
        <div class="image">
          <img class="img-fluid" src="assets/images/test-img.svg" alt="inocentum">
          <div class="name">
            Harshita Shaktawat
          </div>
          <h5 class="company">Artsoul Studio</h5>
        </div>
      </div>
      <div class="item cards">
        <p class="item-text mb-0"><i class="fas fa-quote-left"></i> The site was completed as requested, easy to work
          with and very responsive. Waiting on the webpage to be loaded on the hosting site, but that delay was on us...
          Thanks for the support and we will most certainly use the services again!<i class="fas fa-quote-right"></i>
        </p>
        <div class="image">
          <img class="img-fluid" src="assets/images/test-img.svg" alt="inocentum">
          <div class="name">Jake Clay</div>
          <h5 class="company">Seemercs</h5>
        </div>
      </div>
      <div class="item cards">
        <p class="item-text mb-0"><i class="fas fa-quote-left"></i> quality work!<i class="fas fa-quote-right"></i></p>
        <div class="image">
          <img class="img-fluid" src="assets/images/test-img.svg" alt="inocentum">
          <div class="name">Samar Sisodiya</div>
          <h5 class="company">Entrepreneur | Coach</h5>
        </div>
      </div>
      <div class="item cards">
        <p class="item-text mb-0"><i class="fas fa-quote-left"></i> Very fast and good, Developer he knows his job and I
          will work with him again for sure<i class="fas fa-quote-right"></i></p>
        <div class="image">
          <img class="img-fluid" src="assets/images/test-img.svg" alt="inocentum">
          <div class="name">Hamed Lorzadeh</div>
          <h5 class="company">Dein Deal</h5>
        </div>
      </div>
    </div>
  </div>
</div>
