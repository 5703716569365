import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-return-policy',
  templateUrl: './return-policy.component.html',
  styleUrls: ['./return-policy.component.scss']
})
export class ReturnPolicyComponent implements OnInit {

  constructor(private title: Title ) { }

  ngOnInit(): void {
    this.title.setTitle('Inocentum | Refund & Cancellation Policy');
  }

}
