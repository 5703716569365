<div class="slider-wrap">
  <div class="container-fluid">
    <h1 class="heading-color text-center mb-5">Our  Clients</h1>
    <section class="customer-logos slider mb-4">
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/amar_sisodia.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/blueray.jpg"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/cs_logo.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/gettravel_logo.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/helper.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/hrtnlogo.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/jb_min.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/ln_logo.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/dein_deal.svg"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/my_store.svg"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/logo_feed.jpeg"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/logo_gb.jpg"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/Moments2Move.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/noonstudy.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/pay2mate.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/ravi_sisodiya.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/seemercs_logo.jpeg"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/urena.png"></div>
      <div class="slide"><img class="img-fluid" src="assets/images/client logo/v24.png"></div>
      <div class="slide resort"><i class="fas fa-umbrella-beach"></i>Geetanjali Resort</div>
    </section>
  </div>

</div>
