<div class="cover-area cover-area-property">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 pt-5">
        <img class="img-fluid w-100 pt-5 mt-md-5 mt-0" src="assets/images/blog.png" alt="blog-cover-img">
      </div>
      <div class="col-md-5 cover-about" data-aos="fade-in">
        <h1 class="large-heading text-light">Blog</h1>
        <p class="cover-text">
          Inocentum blog will give you the perfect platform to show others your point of view and share your journey or experience with tech.</p>
      </div>
    </div>
  </div>
</div>
<div class="blog px-md-5 px-0">
  <div class="container-fluid">
    <h1 class="text-center heading-color my-md-5 mt-5 mb-4">Our Blogs</h1>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div *ngFor="let category of categories" class="col-md-12 col-lg-4 mb-md-5 mb-4">
            <a class="d-flex w-100" [routerLink]="['/blog_details/' + category.name]">
              <div class="blog-desc d-inline-block order-md-1 order-2">
                <img class="img-fluid" src="https://cdn.pixabay.com/photo/2021/01/05/06/40/boat-5889919_960_720.png" alt="blog-category">
              </div>
              <div class="d-inline-block order-md-2 order-1 m-3">
                <h5 class="heading-color text-uppercase">{{category.name}}</h5>
                <p [innerHTML]="category.description"></p>
              </div>
            </a>
          </div>
          <div *ngIf="categories && !categories.length" class="col-12 py-5">
            <h4 class="text-muted my-4">No categories to show!</h4>
          </div>
          <ng-template [ngIf]="!categories">
            <div class="col-md-12 col-lg-4 mb-md-5 mb-4">
              <a class="d-flex w-100 skeleton-loader shadow" href="/blog_details" (click)="false"></a>
            </div>
            <div class="col-md-12 col-lg-4 mb-md-5 mb-4">
              <a class="d-flex w-100 skeleton-loader shadow" href="/blog_details" (click)="false"></a>
            </div>
            <div class="col-md-12 col-lg-4 mb-md-5 mb-4">
              <a class="d-flex w-100 skeleton-loader shadow" href="/blog_details" (click)="false"></a>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="col-12 mt-5">
        <h3>Latest Blogs</h3>
      </div>
      <div *ngFor="let blog of blogs" class="col-md-3 col-lg-4 col-sm-2 mb-md-5 mb-4">
        <div class="cards latest-blog p-0">
            <div class="card-img">
              <img class="img-fluid" [src]="blog.feature_image" [alt]="blog.slug">
            </div>
           <div class="p-2 text-left">
             <a [routerLink]="['/single_blog', blog.slug]" class="text-decoration-none">
               <h5 class="heading-color">{{blog.title}}</h5>
             </a>
             <p class="text-ellipsis d-none" [innerHTML]="blog.body"></p>
             <small class="text-secondary">{{blog.date_time | date: 'medium'}}</small>
           </div>
          </div>
      </div>
      <div *ngIf="blogs && !blogs.length" class="col-12 py-5">
        <h4 class="text-muted my-4">No blog to show!</h4>
      </div>
      <ng-template [ngIf]="!blogs">
        <div class="col-md-6 mb-5">
          <div class="skeleton-loader shadow"></div>
        </div>
        <div class="col-md-6 mb-5">
          <div class="skeleton-loader shadow"></div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
