import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
declare const AOS;

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit, AfterViewInit {

  constructor(private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Inocentum | Portfolio');
    this.meta.updateTag({ name: 'description', content: 'See modern and update portfolio of Inocentum Technologies' });
    this.meta.updateTag({name: 'robots', content: 'index, follow'});
  }
  ngAfterViewInit(): void {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }

}
