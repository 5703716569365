<div class="cover-area cover-area-property">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 pt-5">
        <img class="img-fluid w-100 pt-5 mt-md-5 mt-0" src="assets/images/portfolio.png" alt="">
      </div>
      <div class="col-md-5 cover-about" data-aos="fade-in">
        <h1 class="large-heading text-white">Portfolio</h1>
        <p class="cover-text">
          Some of our work portfolio mentioned bellow, please explore our works. Hopefully you will like the quality and afford we dedicated towards the work.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="portfolio-cards" data-aos="fade-up">
  <h1 class="text-center my-md-5 mb-4 mt-5 heading-color">Our Clients</h1>
  <div class="container-fluid">
    <div class="container">
      <div class="row justify-content-center wip">
        <div class="col-lg-3 card-wrap col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">247Gettravel</h2>
            <img class="img-fluid my-3" src="assets/images/gettravel.png" alt="">
            <a class="btn btn-primary" href="https://www.247gettravel.com" target="_blank">Visit Site</a>
          </div>
        </div>
        <div class="col-lg-3 card-wrap col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">Pay2mate</h2>
            <img class="img-fluid my-3" src="assets/images/pay2mate.png" alt="inocentum">
            <a class="btn btn-primary" href="https://pay2mate.com" target="_blank">Visit Site</a>
          </div>
        </div>
        <div class="col-lg-3 card-wrap col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">Trivocity</h2>
            <img class="img-fluid my-3" src="assets/images/trivocity.png" alt="inocentum">
            <a class="btn btn-primary" href="https://www.trivocity.com" target="_blank">Visit Site</a>
          </div>
        </div>
        <div class="col-lg-3 card-wrap col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">LNFinancial</h2>
            <img class="img-fluid my-3" src="assets/images/lnfinancial.png" alt="inocentum">
            <a class="btn btn-primary" href="https://lnfinancial.com.sg" target="_blank">Visit Site</a>
          </div>
        </div>
        <div class="col-lg-3 card-wrap col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">FEED</h2>
            <img class="img-fluid my-3" src="assets/images/feed.png" alt="">
            <a class="btn btn-primary" href="https://ourfeed.in" target="_blank">Visit Site</a>
          </div>
        </div>
        <div class="col-lg-3 card-wrap col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">Ravi Sisodiya</h2>
            <img class="img-fluid my-3" src="assets/images/ravisisodiya.png" alt="inocentum">
            <a class="btn btn-primary" href="https://ravisisodiya.com" target="_blank">Visit Site</a>
          </div>
        </div>
        <div class="col-lg-3 card-wrap col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">Seemercs</h2>
            <img class="img-fluid my-3" src="assets/images/seemercs.png" alt="inocentum">
            <a class="btn btn-primary" href="https://seemercs.org" target="_blank">Visit Site</a>
          </div>
        </div>
        <div class="col-lg-3 card-wrap col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">Samar Sisodia</h2>
            <img class="img-fluid my-3" src="assets/images/samar.png" alt="inocentum">
            <a class="btn btn-primary" href="https://www.samarsisodia.com" target="_blank">Visit Site</a>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">The Study Corner</h2>
            <img class="img-fluid my-3" src="assets/images/coaching.png" alt="">
            <a class="btn btn-primary" href="https://thestudycorner.in" target="_blank">Visit Site</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="demo-cards" data-aos="fade-up">
  <h1 class="text-center my-5 heading-color">Our Demo Templates</h1>
  <div class="container-fluid">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">SinglePage Portfolio</h2>
            <img class="img-fluid my-3" src="assets/images/singlepage.png" alt="">
            <a class="btn btn-primary" href="https://singlepageportfolio.inocentum.online/" target="_blank">Visit Site</a>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="cards">
            <h2 class="mb-0">College Website Template</h2>
            <img class="img-fluid my-3" src="assets/images/college_site.png" alt="">
            <a class="btn btn-primary" href="https://collegewebsitetemplate.inocentum.online/" target="_blank">Visit
              Site</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
