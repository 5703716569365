<div class="cover-area cover-area-property">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <img class="img-fluid" src="assets/images/blogging.png" alt="">
      </div>
      <div class="col-md-5" data-aos="fade-left">
        <h1 class="large-heading text-light">Blog Details</h1>
        <p class="cover-text">Welcome to Inocentum technologies, we are not only a trustworthy company but also your
          digital partner.
        </p>
      </div>
    </div>
  </div>
</div>
<div *ngIf="blogs.length" class="container mt-4 mt-md-0">
  <div class="row mb-5">
    <div class="col-12 mb-2">
      <h1 class="heading-color">
        <span class="text-muted">Category : </span>
        {{category}}
      </h1>
    </div>
    <div *ngFor="let blog of blogs; let isEven = even" class="col-md-{{isEven ? 8 : 4}}">
      <a class="d-flex w-100 shadow" [routerLink]="['/single_blog', blog.slug]">
        <div class="blog-desc d-inline-block order-md-1 order-2">
          <img class="img-fluid" [src]="blog.feature_image" [alt]="blog.slug">
        </div>
        <div class="d-inline-block order-md-2 order-1 p-3">
          <h5 class="heading-color">{{blog.title}}</h5>
          <small class="text-dark d-none" [innerHTML]="blog.body.substring(0, 150) + '...'"></small>
        </div>
      </a>
    </div>
  </div>
  <div class="text-center">
    <button *ngIf="nextLink && !loadingBlogs" class="btn btn-primary mb-5" (click)="getBlogsByCategory(nextLink)">Load More</button>
  </div>
  <!-- Skeleton Loading -->
  <div *ngIf="loadingBlogs" class="row mb-5">
    <div class="col-md-7">
      <a class="d-flex w-100 skeleton-loader shadow" routerLink="/no_blog">
        <div class="w-100 h-100"></div>
      </a>
      <div class="row">
        <div class="col-md-6">
          <a class="d-flex w-100 skeleton-loader shadow" routerLink="/no_blog">
            <div class="w-100 h-100"></div>
          </a>
        </div>
        <div class="col-md-6">
          <a class="d-flex w-100 skeleton-loader shadow" routerLink="/no_blog">
            <div class="w-100 h-100"></div>
          </a>
        </div>
        <div class="col-md-6">
          <a class="d-flex w-100 skeleton-loader shadow" routerLink="/no_blog">
            <div class="w-100 h-100"></div>
          </a>
        </div>
        <div class="col-md-6">
          <a class="d-flex w-100 skeleton-loader shadow" routerLink="/no_blog">
            <div class="w-100 h-100"></div>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <a class="d-flex w-100 skeleton-loader shadow" routerLink="/no_blog">
        <div class="w-100 h-100"></div>
      </a>
      <a class="d-flex w-100 skeleton-loader shadow" routerLink="/no_blog">
        <div class="w-100 h-100"></div>
      </a>
      <a class="d-flex w-100 skeleton-loader shadow" routerLink="/no_blog">
        <div class="w-100 h-100"></div>
      </a>
    </div>
  </div>
</div>
