import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ClientComponent } from './client/client.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { MissionComponent } from './mission/mission.component';
import { RatingComponent } from './rating/rating.component';
import { ContactComponent } from './contact/contact.component';
import { ServiceComponent } from './service/service.component';
import { CareerComponent } from './career/career.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { TNCComponent } from './t-n-c/t-n-c.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import {HttpClientModule} from '@angular/common/http';
import {ApiService} from './api-service/api.service';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { GraphicsDesigningComponent } from './graphics-designing/graphics-designing.component';
import { BlogComponent } from './blog/blog.component';
import { BlogCardsComponent } from './blog-cards/blog-cards.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import {SingleBlogComponent} from "./single-blog/single-blog.component";
import {NoBlogComponent} from "./no-blog/no-blog.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ClientComponent,
    TestimonialComponent,
    MissionComponent,
    RatingComponent,
    ContactComponent,
    ServiceComponent,
    CareerComponent,
    PrivacyComponent,
    ReturnPolicyComponent,
    TNCComponent,
    PortfolioComponent,
    DigitalMarketingComponent,
    GraphicsDesigningComponent,
    BlogComponent,
    BlogCardsComponent,
    BlogDetailsComponent,
    SingleBlogComponent,
    NoBlogComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule
    ],
  providers: [ApiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
