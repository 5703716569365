<div class="bg-cover">
  <div class="container cover-space">
    <div class="content-box">
      <div class="cover-text">
        <h1 class="text-center">Refund and Cancellation Policy</h1><br>
        <p class="text-justify">Our focus is complete customer satisfaction. In the event, if you are displeased with the
          services provided, we will refund back the money, provided the reasons are genuine and proved after investigation.
          Please read the fine prints of each deal before buying it, it provides all the details about the services or the
          product you purchase.
        </p><br>
        <p class="text-justify">In case of dissatisfaction from our services, clients have the liberty to cancel their
          projects and request a refund from us. Our Policy for the cancellation and refund will be as follows:</p><br>
        <h5>Cancellation Policy</h5>
        <p class="text-justify">For Cancellations please contact the us via contact us link.</p><br>
        <p class="text-justify">We offer 15 days cancellation policy for most of our services. If otherwise, it will be
          mentioned in the service details page.
        </p>
        <h5>Refund Policy</h5>
        <p class="text-justify">We will try our best to create the suitable design concepts for our clients.</p><br>
        <p class="text-justify">
          In case any client is not completely satisfied with our products we can provide a refund.</p><br>
        <p class="text-justify mb-0">Refund can be processed by means as per customer preference.</p>
      </div>
    </div>
  </div>
</div>
