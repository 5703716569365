import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {ApiService} from "../api-service/api.service";
declare let AOS;
@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  private routeSubscription: Subscription;
  public category: string;
  public blogs = [];
  public loadingBlogs = false;
  public nextLink;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(
      event => {
        if(event.category && event.category.length){
          this.category = String(event.category).trim();
          this.getBlogsByCategory();
        }else this.router.navigate(['no_blog'], {replaceUrl: true}).then();
      }
    );
  }

  public getBlogsByCategory(nextLink = null){
    this.loadingBlogs = true;
    this.apiService.getBlogs(nextLink, (!nextLink ? this.category : null)).subscribe(
      (data: any) => {
        this.loadingBlogs = false;
        if(!data.results.length){
          this.router.navigate(['no_blog'], {replaceUrl: true}).then();
          return;
        }
        this.nextLink = data.next;
        this.blogs = [...this.blogs, ...data.results];
      },
      () => this.loadingBlogs = false
    );
  }

  ngAfterViewInit(): void {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }

  ngOnDestroy() {
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
  }
}
