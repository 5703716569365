import {AfterViewInit, Component, OnInit} from '@angular/core';
import {showAlert} from '../contact/contact.component';
import {ApiService} from '../api-service/api.service';
import { Meta, Title } from '@angular/platform-browser';
declare const AOS;
declare const $;
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit, AfterViewInit {
  emailRegex = new RegExp(/[^@]+@[^.]+\..+/);
  strReg = new RegExp(/^[a-zA-Z\s]*$/);
  // tslint:disable-next-line:max-line-length
  formValues = {email: {value: '', error: false}, firstName: {value: '', error: false}, lastName: {value: '', error: false}, desiredField: {value: '', error: false},
    file: {value: '', error: false}};
  alert = {show: false, type: '', message: ''};
  showAlert = showAlert;

  constructor(private apiService: ApiService, private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Inocentum | Career');
    this.meta.updateTag({ name: 'description', content: 'Start your IT career with Inocentum Technologies - the best software company' });
    this.meta.updateTag({name: 'robots', content: 'index, follow'});
  }
  ngAfterViewInit(): void {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }

  careerSubmit(event: Event){
    event.preventDefault();
    let condition = true;
    if(this.formValues.email.value.trim().match(this.emailRegex) === null) {
      condition = false;
      this.formValues.email.error = true;
    }
    if(this.formValues.firstName.value.trim().length < 2 || this.formValues.firstName.value.trim().match(this.strReg) === null) {
      condition = false;
      this.formValues.firstName.error = true;
    }
    if (this.formValues.desiredField.value.trim().length === 0) {
      condition = false;
      this.formValues.desiredField.error = true;
    }
    if(this.formValues.file.value === '' || this.formValues.file.value['name'].split('.').pop().toLowerCase() !== 'pdf') {
      condition = false;
      this.formValues.file.error = true;
    }
    if(condition){
      document.querySelector('button[type=submit]').setAttribute('disabled', "true");
      let formData = new FormData();
      formData.append('first_name', this.formValues.firstName.value);
      formData.append('last_name', this.formValues.lastName.value);
      formData.append('email', this.formValues.email.value);
      formData.append('desired_field', this.formValues.desiredField.value);
      formData.append('cv', this.formValues.file.value);
      this.apiService.submitJob(formData).subscribe(
        ()=>{
          document.querySelector('button[type=submit]').removeAttribute('disabled');
          this.showAlert(this.alert);
          this.alert.type = 'success';
          this.alert.message = 'Thank you! Our HR team will contact you soon.';
          setTimeout(() => {
            $('#apply_now_modal').modal('hide');
          }, 5000);
        },
        ()=>{
          document.querySelector('button[type=submit]').removeAttribute('disabled');
          this.showAlert(this.alert);
          this.alert.type = 'danger';
          this.alert.message = 'Something went wrong, please try again later';
        }
      );
    }
  }

}
